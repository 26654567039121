.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  font-size: larger;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.line-break {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  height: 3;
  width: 100%;
  margin: 30px 0px 0px 0px;
}

/*--------- Homepage --------- */

.Home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/*--------- Login Page --------- */

.login-background {
  background: url(landing-img.png);
  background-repeat: no-repeat;
  display: flex;
  flex: 1;
  background-size: 500px;
  background-position: bottom;
  background-attachment: inherit;
}

.login-background-employer {
  background: url(employerlogin.jpeg);
  background-repeat: no-repeat;
  display: flex;
  flex: 1;
  background-size: cover;
  background-position: bottom;
  background-attachment: inherit;
  border-radius: 20px 300px;
}

.login-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background-color: white;
}

.exempla-text {
  color: #000000b0;
  font-size: 50px;
  font-weight: 200;
}

.ttp-logo {
  width: 50%;
  align-self: center;
}

.exempla-logo {
  vertical-align: middle;
  border-style: none;
  height: 40px;
}
.Auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
}

.Auth {
  display: flex;
  width: 400px;
  max-width: 100%;
}

/*--------- Landing Page  --------- */

.landing-header {
  text-align: left;
  margin: 50px;
}

.landing-subtitle {
  margin: 20px;
  font-size: 35px;
  font-weight: 700;
}

.landing-subtitle-subtext {
  max-width: 600px;
  font-size: 20px;
  font-weight: 500;
}

.subtitles-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fffefe;
}
.gradient-background {
  background: linear-gradient(-45deg, #8835fe, #dd6def, #5e14e7, #b586f7);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  width: 100%;
  display: flex;
  justify-content: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 25%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.landing-header > h1 {
  margin-bottom: 0px;
  line-height: normal;
  font-weight: 800;
  font-size: 70px;
}

.landing-login-buttons {
  margin: 20px;
  width: fit-content;
}

.landing-candidate-info {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.candidate-img {
  max-width: 30%;
  max-height: 30vw;

  border-radius: 0px 0px 100px 10px;
  align-self: flex-start;
}

.candidate-info {
  width: 70%;
  padding: 0px 50px 0px 50px;
  text-align: left;
}

.candidate-info > h3 {
  font-size: 20px;
}

.candidate-info-header {
  font-size: 30px;
  font-weight: 700;
}

/* ---- Title Animation */

.build {
  animation: showTopText 1s;
}

.showcase {
  animation: showTopText 1.5s;
}

.hired {
  animation: showTopText 2s;
}

@keyframes showTopText {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

/* ---------- Company  Landing ------- */

.landing-company-info {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.company-img {
  max-width: 30%;
  max-height: 30vw;

  border-radius: 0px 0px 10px 100px;
  align-self: flex-start;
}

.company-info {
  width: 70%;
  padding: 0px 50px 0px 50px;
  text-align: left;
}

.company-info > h3 {
  font-size: 20px;
}

.company-info-header {
  font-size: 30px;
  font-weight: 700;
}
/*---------- Navbar -----------*/

.Nav-links {
  justify-content: right;
  display: grid;
}

.Nav-spacer {
  display: inline-flex;
  width: 100%;
}

.Nav-logo {
  width: 200px;
  margin: 0px 15px 0px 15px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  color: white;
  font-weight: 600;
}

.logo-navbar {
  display: flex;
  height: 70%;
  width: auto;
  margin: auto;
  margin-left: 10px;
}

/* Profile */

.profile-header {
  margin-top: 20px;
  background: linear-gradient(to bottom, #8835fe 60%, white 0%);
}

.profile-user-info {
  max-width: 600px;
  display: inline-block;
  flex-direction: column;
  margin: 20px;
}

.profile-user-info-shadow-box {
  display: inline-block;
  flex-direction: column;
  width: 100vw;
  background: white;
  border: 1px solid #f3f3f3;
  border-top: none;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: center;
}

.profile-user-projects-shadow-box {
  margin-top: 40px;
  margin-bottom: 40px;
  display: inline-block;
  flex-direction: column;
  width: 100vw;
  background: white;
  border: 1px solid #f3f3f3;
  border-top: none;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: center;
}

.edit-profile-button {
  margin-top: 20px;
}

.logo {
  display: flex;
  flex-direction: row;
  height: 100%;
  float: left;
}

.logo > p {
  font-weight: 300;
  font-size: 25px;
  margin-left: 20px;
  letter-spacing: 3px;
}

/* ---- Candidate Dashboard ------*/



.candidate-dashboard-table-container {
  max-width: 1200px;
  justify-content: center;
  display: inline-grid;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
}

.candidate-dashboard {
  margin-top: 20px;
}

.candidate-dashboard-table-title-container > h1 {
  font-weight: 800;
}
/* Homepage */

.homepage-header {
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 0px;
}

.event-header-submissions {
  font-size: 45px;
  font-weight: 800;
}

.event-list {
  display: inline-flex;
}

.ant-row {
  justify-content: center;
}


.layout {
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  padding-bottom: 4px;
}

/* buttons */

.ant-btn .ant-btn-primary {
  background: #000000;
  border-color: #242729;
}

/* event */

.event-form {
  display: inline-flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
}

.ant-card  {
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
  0 5px 10px rgba(166, 173, 201, 0.2);
}


.event-page-shadow {
  display: inline-block;
  flex-direction: column;
  width: 100vw;
  background: white;
  border: 1px solid #f3f3f3;
  border-top: none;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: center;
}

.event-page-shadow-submissions {
  margin-top: 30px;
  display: inline-block;
  flex-direction: column;
  width: 100vw;
  background: white;
  border: 1px solid #f3f3f3;
  border-top: none;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: center;
}






/* ---- expand event details ----- */

.panel-wrapper {
  position: relative;
}

.btn {
  color: #fff;
  background: #000;
  border-radius: 1.5em;
  left: 30%;
  padding: 1em;
  text-decoration: none;
  width: 40%;
}
.show,
.hide {
  position: absolute;
  bottom: -1em;
  z-index: 100;
  text-align: center;
}

.hide {
  display: none;
}
.show:target {
  display: none;
}
.show:target ~ .hide {
  display: block;
}
.show:target ~ .panel {
  max-height: 2000px;
}
.show:target ~ .fade {
  margin-top: 0;
}

.panel {
  position: relative;
  margin: 2em auto;
  width: 60%;
  max-height: 130px;
  overflow: hidden;
  transition: max-height 0.6s ease;
  text-align: left;
  max-width: 775px;
}
.fade {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 50%
  );
  height: 100px;
  margin-top: -100px;
  position: relative;
}

.panel > h1 {
  font-size: 30px;
  font-weight: 800;
}

.panel > div > span > a {
  font-size: 16px;
}

.panel > div > ul > li > a {
  font-size: 16px;
}

.panel > div > h1 {
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 5px;
}

/* submission card */

.submission-card-body {
  display: inline-grid;
}

.submission-card-body > a {
  line-height: 1em;
}

.submit-img {
  background-color: lightgrey;

  height: 182px;
}

/* Company Profile */

.company-profile-header {
  margin-top: 20px;
  background: linear-gradient(to bottom, #8835fe 60%, #eff2f5 0%);
}

.company-profile-info {
  margin-top: 40px;
  width: 100%;
  max-width: 800px;
  display: inline-block;
  flex-direction: column;
}

.profile-socials-company {
  background: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: left;
  padding: 10px 50px 0px 50px;
  margin-top: 20px;
}

.company-profile-info > h1 {
  font-size: 40px;
}

.company-bio-container {
  width: 100%;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: left;
  padding: 10px 50px 0px 50px;
}

.profile-socials-company > h2 {
  font-size: 30px;
}

.company-bio-container > h2 {
  font-size: 30px;
}

.edit-profile-button {
  margin-top: 20px;
}

.logo {
  display: flex;
  flex-direction: row;
  height: 100%;
  float: left;
}

.logo > p {
  font-weight: 300;
  font-size: 25px;
  margin-left: 20px;
  letter-spacing: 3px;
}

/* -------- Employer Dashboard ------ */

.ant-menu-item > span {
  color: #454343;
}

.horizontal-line {
  border-bottom: 1px solid lightgray;
  width: 80%;
  margin: 20px auto 20px auto;
}

.ant-layout-sider-children {
  height: fit-content;
}

.ant-layout-sider .ant-layout-sider-dark {
  height: 100vh;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: none;
  
}

/* ----- Create Assessment Form ------- */

.create-assessment-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.company-create-assessment-form {
  justify-content: center;
  display: inline-flex;
  width: 70%;
  max-width: 600px;
}

.create-assessment-container .ant-form-item-label > label {
  font-weight: 700;
}

.create-assessment-container > input {
  border-radius: 10px;
}

.create-assessment-container > h1 {
  font-size: 30px;
  font-weight: 600;
}

/* ----- Create Position Form ------- */

.create-position-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.company-create-position-form {
  justify-content: center;
  display: inline-flex;
  width: 70%;
  max-width: 600px;
}

.create-position-container .ant-form-item-label > label {
  font-weight: 700;
}

.create-position-container > input {
  border-radius: 10px;
}

.create-position-container > h1 {
  font-size: 30px;
  font-weight: 600;
}

/* ------ Candidate Overview Table -----  */
.candidate-table-container {
  max-width: 1200px;
  justify-content: center;
  display: inline-grid;
}

.candidate-overview-table{
  margin: 25px 5px
}

/* ------ Company Assessment View -----  */


.company-assessment-info-container {
  width: 75%;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: left;
  padding: 10px 50px 0px 50px;
  display: inline-flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
}

.company-assessment-info {
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  width: 80%;
  max-width: 1000px;
}

.company-assessment-details > h1 {
  font-size: 40px;
  font-weight: 500;
}

.company-assessment-details .company-assessment-info-container > h2 {
  font-size: 25px;
  font-weight: 500;
}

/* ----- Exempla suggestion form ---- */

/* ------- Position View ---- */

.position-overview-info-background{
  background: linear-gradient(to bottom, #4f00ff75 6%, rgba(255, 255, 255, 0) 0%);
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

}

.suggestion-form .ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  max-width: 320px;
}

.vertical-line {
  height: 50px;
  align-self: center;

  border-left: 2px solid #cac8c8;
}
.position-overview-table {
  max-width: 1200px;
  justify-content: center;
  display: inline-grid;
  margin: 10px
}

.position-overview-container {
  background: none;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 65%
}

.position-info-container {
  width: 95%;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: left;
  padding: 10px 50px 0px 50px;
  display: inline-flex;
  justify-content: space-around;
  margin-top: 20px;
}

.position-info-container > span > p {
  margin-bottom: 0px;
  color: #00000075;
  font-size: 15px;
}

.position-info-container > span > h1 {
  font-size: 25px;
  font-weight: 700;
}

.position-info-container > span {
  margin: 0px 15px 0px 15px;
  text-align: center;
  flex: 1;
  display: grid;
}

.position-details-container > span {
  margin: 0px 15px 0px 15px;
  text-align: center;
}

.position-candidate-container {
  margin-top: 20px;
}

.position-details-container {
  width: 70%;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: left;
  padding: 10px 50px 0px 50px;
  display: inline-flex;
  justify-content: space-around;
  margin-top: 20px;
  text-align: center;
}

.position-link-container {
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(154, 160, 185, 0.05),
    0 5px 10px rgba(166, 173, 201, 0.2);
  text-align: left;
  display: inline-flex;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 10px;
  align-items: center;
}

.position-link-container > p {
  margin-bottom: 0px;
}

.position-candidate-container {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .logo {
    display: none;
  }
  .logo-navbar {
    display: none;
  }
  .Nav-links {
    justify-content: center;
  }
  .login-background {
    display: none;
  }
}
